<template>
  <div class="cgu-page">
    <div class="body pb-40">
    <CGU/>
    </div>
    <div class="footer">
      <the-footer/>
    </div>
  </div>
</template>

<script>
import TheFooter from '../../components/the-footer'
import CGU from '../../components/helper/cgu/cgu-page.vue'
export default {
  name: 'index',
  components: { CGU, TheFooter },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  @media only screen and (max-width: 600px) {
    .body {
      // padding-top: 40rem;
      // padding-bottom: 40rem;
    }
    .debt {
      width: 100%;
    }
  }

</style>
