<template>
  <div class="the-footer">
    <div class="begin px-24 py-24 flex items-start justify-between">
      <div class="first w-1/2 leading-relaxed">
        <div class="logo mb-4">
          <img :src="picture" class="h-16 cursor-pointer" alt="logo">
        </div><br>
        <div class="description w-3/5">{{ $t('oremiFooterTitle') }}</div><br>
        <div class="rights">
          <div>{{ '© ' + new Date().getFullYear() + $t('allRightReserved') }}</div>
          <div class="mt-4">By <span class="cursor-pointer" @click="goToDevs">41Devs</span></div>
        </div><br>
      </div>
      <div class="first w-1/3 leading-relaxed">
        <div class="title text-black font-bold mb-4">{{ $t('insurances') }}</div><br>
        <div class="desc cursor-pointer" @click="goToAuto">{{ $t('automobile') }}</div><br>
        <div class="desc cursor-pointer" @click="goToMoto">{{ $t('moto') }}</div><br>
        <div class="desc cursor-pointer" @click="goToTravel">{{ $t('travel') }}</div><br>
        <div class="desc cursor-pointer" @click="goToHabitation">{{ $t('habitation') }}</div><br>
      </div>
      <div class="first w-1/3 leading-relaxed">
        <div class="title text-black font-bold mb-4">{{ $t('legalInformation') }}</div><br>
        <div class="desc cursor-pointer" @click="goToMention">{{ $t('legalMention') }}</div><br>
        <div class="desc cursor-pointer" @click="goToCgu">{{ $t('userGeneralCondition') }}</div><br>
        <div class="desc cursor-pointer" @click="goToPolitique">{{ $t('confidentiality') }}</div><br>
        <div class="desc cursor-pointer" @click="goToWebSite">{{ $t('cookies') }}</div><br>
      </div>
      <div class="first w-1/3 leading-relaxed">
        <div class="title text-black font-bold mb-4">{{ $t('assistance') }}</div><br>
        <div class="desc cursor-pointer" @click="goToWebSite">{{ $t('helpHeader') }}</div><br>
        <div class="desc cursor-pointer" @click="goToFAQ">{{ $t('faqHeader') }}</div><br>
        <div class="desc cursor-pointer" @click="goToContacts">{{ $t('contactHeader') }}</div><br>
      </div>
    </div>
    <div class="rights-mobile text-center">
      <div>{{ '© ' + new Date().getFullYear() + $t('allRightReserved') }}</div>
      <div class="mt-4">By <span class="cursor-pointer" @click="goToDevs">41Devs</span></div>
    </div><br>
    <div class="whatsapp fixed z-50">
      <svg-icon
        name="ic_whatsapp"
        original
        class="w-12 h-12 cursor-pointer"
        @click="goToWhatsapp"
      />
    </div>
  </div>
</template>

<script>
import image from '../assets/images/img_logo.webp'
export default {
  name: 'the-footer',
  data () {
    return {
      picture: image
    }
  },
  methods: {
    goToAuto () {
      this.$router.push({ path: '/auto' })
    },
    goToMoto () {
      this.$router.push({ path: '/moto' })
    },
    goToTravel () {
      this.$router.push({ path: '/voyage' })
    },
    goToHabitation () {
      this.$router.push({ path: '/habitation' })
    },
    goToFAQ () {
      this.$router.push({ path: '/faq' })
    },
    goToWhatsapp () {
      window.open('https://api.whatsapp.com/send?phone=22951784862', '_blank')
    },
    goToWebSite () {
      this.$router.push({ path: '/politique-cookies' })
    },
    goToContacts () {
      window.open('https://www.atlantiqueassurances.bj/nous-contacter/', '_blank')
    },
    goToDevs () {
      window.open('https://41devs.com/', '_blank')
    },
    goToCgu () {
      this.$router.push({ path: '/cgu' })
    },
    goToPolitique () {
      this.$router.push({ path: '/politique-confidentialite' })
    },
    goToMention () {
      this.$router.push({ path: '/mention' })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .the-footer {
    background: $aab_footer_background;
  }
  .whatsapp {
    bottom: 1rem;
    right: 1rem;
  }
  .rights-mobile {
    display: none;
  }
  .desc, .description, .rights {
    font-weight: 100;
  }
  @media only screen and (max-width: 600px) {
    .begin {
      flex-wrap: wrap;
    }
    .first {
      width: 100%;
      line-height: 1rem;
    }
    .description {
      line-height: 1.625rem;
    }
    .rights {
      display: none;
    }
    .desc, .description, .rights {
      font-size: 1.25rem;
    }
    .title {
      margin-top: 1.5rem;
    }
    .rights-mobile {
      display: block;
    }
  }
  @media only screen and (max-width: 1024px) {
    .begin {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
</style>
